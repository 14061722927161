@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap");
.App,
html,
#root,
body {
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  display: flex;
  flex-flow: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action {
  align-items: center;
  border-radius: 4px;
  border-style: none;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  color: white;

  height: 44px;
  min-width: 78px;
  padding: 0 1em;

  transition: background-color 0.5s ease;
}

.action:hover {
  background-color: rgba(136, 173, 241, 85%);
}
